import axios from 'axios'
import React, { useEffect, useState, memo } from 'react'
import useInView from './useInView'

const stripHtmlAndReturnText = htmlString =>
  htmlString.replace(/(<([^>]+)>)/gi, '')

const baseEndpoint = `${window.wrwps_site_url}wp-json/wp/v2`

const fallbackImageUrl = window.wrwps_fallback_featured_image
const EXCERPT_MAX_CHARS = 66

const PostCard = ({ hasImg, post, fetched_tags }) => {
  const {
    title: { rendered: renderedTitle },
    content: { rendered: renderedContent },
    featured_media: media_ID,
    tags = false,
    link,
    index,
    acf: { is_3rd_party_link, third_party_link }
  } = post

  const { containerRef, isVisible } = useInView({
    rootMargin: '90px 0px 0px 0px',
    threshold: 0.2
  })

  const [excerpt, setExcerpt] = useState(null)
  const [featuredMediaImg, setFeaturedMediaImg] = useState(null)
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  const fetchFeaturedMediaImg = async () => {
    try {
      const res = await axios.get(`${baseEndpoint}/media/${media_ID}`)
      if (res.status !== 200) {
        setIsLoadingCategories(false)
        throw new Error('Bad request for categories.')
      }

      // setmedia here
      const image_url = res.data.source_url
      setFeaturedMediaImg(image_url)

      setIsLoadingImage(false)
    } catch (err) {
      console.log('image err: ', err)
      setIsLoadingImage(false)
    }
  }

  useEffect(() => {
    // get and trim excerpt
    const escapedContent = stripHtmlAndReturnText(renderedContent)
    let trimmedContent = escapedContent.substring(0, EXCERPT_MAX_CHARS).trim()
    if (escapedContent.length > EXCERPT_MAX_CHARS) {
      trimmedContent += '...'
    }
    setExcerpt(trimmedContent)

    // fetch featured image initially
    if (media_ID) {
      fetchFeaturedMediaImg()
    } else {
      setIsLoadingImage(false)
    }
  }, [])

  const tagObj = fetched_tags.find(tag => tag.id === tags[0])

  const isInViewClass = isVisible ? 'ivtr ivtr-btm inView' : 'ivtr ivtr-btm'

  let link_url = link
  let link_attrs = {}
  if (is_3rd_party_link) {
    link_url = third_party_link
    link_attrs = { target: '_blank', rel: 'noopener noreferrer' }
  }

  return (
    <article
      className={`post-wrap ${hasImg ? 'post-wrap--img' : ''} ${isInViewClass}`}
      ref={containerRef}
    >
      <div className="post-wrap__inner">
        {/* {JSON.stringify(post, null, 2)} */}
        <div className="post-text-wrap">
          {renderedTitle && (
            <a href={link_url} {...link_attrs}>
              <h6>{renderedTitle}</h6>
            </a>
          )}
          {excerpt && <p className="post-text">{excerpt}</p>}
          {tags && !is_3rd_party_link && (
            <div>
              {tags.length && tagObj ? (
                <p
                  className="post-tag"
                  dangerouslySetInnerHTML={{ __html: tagObj.name }}
                />
              ) : null}
            </div>
          )}
        </div>
        <div className="post-img-wrap">
          <a href={link_url} {...link_attrs}>
            {isLoadingImage && (
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
            )}
            {!isLoadingImage && (
              <img
                src={featuredMediaImg ? featuredMediaImg : fallbackImageUrl}
                alt={renderedTitle}
              />
            )}
          </a>
        </div>
      </div>
    </article>
  )
}

export default memo(PostCard)
