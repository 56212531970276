import React, { memo } from 'react'
import Loader, { GhostLoader } from '../Loader/Loader'
import useFetchLogic from './useFetchLogic'
import DropdownSelect from '../DropdownSelect/DropdownSelect'
import PartnersArchivePostsWrap from '../ArchivePostsWrap/PartnersArchivePostsWrap'

const PartnersArchive = () => {
  const {
    isLoadingTerms,
    terms,
    switchTerm,
    activeTerm,
    error,
    shouldShowLoader,
    posts,
    isLoading,
    postsCount,
    nothingFound,
    initialLoadFinished,
    currentPage,
    setCurrentPage,
    ifShowLoader,
    loaderRef,
    ifShowLoaderSpinner,
    totalPagesCount,
    handleOnClick
  } = useFetchLogic({
    per_page: 12,
    typeEndpoint: 'partners',
    loadMoreMethod: 'onClick',
    defaultTaxObj: {
      name: 'All Categories',
      slug: 'all-categories',
      id: 'all-categories'
    },
    qsParam: 'category',
    shouldFetchTerms: true,
    termsUrlPart: 'partners_categories'
  })

  return (
    <>
      {!isLoadingTerms && terms.length ? (
        <div className="postsWrapFilter">
          <DropdownSelect
            onSelectNew={switchTerm}
            options={terms}
            activeOption={activeTerm}
            label="Filter by categories:"
          />
        </div>
      ) : null}

      {error && <div className="react-error">{error}</div>}
      {shouldShowLoader ? (
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      ) : null}

      {posts.length ? (
        <PartnersArchivePostsWrap
          fetched_categories={terms}
          posts={posts}
          postsCount={postsCount}
        />
      ) : null}

      {nothingFound && initialLoadFinished && (
        <div className="no-posts-wrap">
          <h3>No Posts Found</h3>
          <p>Try updating the filter</p>
        </div>
      )}

      {totalPagesCount && totalPagesCount > 1 ? (
        <>
          {ifShowLoader ? <GhostLoader ref={loaderRef} /> : null}
          {ifShowLoaderSpinner ? (
            <Loader handleOnClick={handleOnClick} isSpinning={isLoading} />
          ) : null}
        </>
      ) : null}
    </>
  )
}

export default memo(PartnersArchive)
