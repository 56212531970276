import React, { memo } from 'react'
import PartnerPostCard from '../PostCard/PartnerPostCard'

const ArchivePostsWrap = ({ posts, fetched_tags }) => {
  return (
    <div className="our-partners-grid f-row-v2">
      {posts.map((post, index) => {
        return (
          <PartnerPostCard
            key={post.id}
            index={index}
            fetched_tags={fetched_tags}
            post={post}
          />
        )
      })}
    </div>
  )
}

export default memo(ArchivePostsWrap)
