import React from 'react'
import ReactDOM from 'react-dom'
import Archive from './react-js/components/Archive/Archive'
import PartnersArchive from './react-js/components/Archive/PartnersArchive'

console.log('react')

class ReactComponents {
  constructor() {
    this.$newsArchiveReactBlock = document.getElementById(
      'js-sec-news-react-block'
    )
    this.$insightsArchiveReactBlock = document.getElementById(
      'js-sec-insights-react-block'
    )
    this.$partnersArchiveReactBlock = document.getElementById(
      'js-sec-partners-react-block'
    )
    this.init()
  }

  init() {
    if (this.$newsArchiveReactBlock) {
      ReactDOM.render(
        <Archive typeEndpoint="posts/?categories=4" qsParam="year" />,
        this.$newsArchiveReactBlock
      )
    }

    if (this.$insightsArchiveReactBlock) {
      ReactDOM.render(
        <Archive typeEndpoint="posts/?categories=5" />,
        this.$insightsArchiveReactBlock
      )
    }
    if (this.$partnersArchiveReactBlock) {
      ReactDOM.render(<PartnersArchive />, this.$partnersArchiveReactBlock)
    }
  }
}

new ReactComponents()
