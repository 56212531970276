import axios from 'axios'
import React, { useEffect, useState, memo } from 'react'
import useInView from './useInView'

const stripHtmlAndReturnText = htmlString =>
  htmlString.replace(/(<([^>]+)>)/gi, '')

const baseEndpoint = `${wrwps_site_url}wp-json/wp/v2`

const PartnerPostCard = ({ post, fetched_tags }) => {
  const {
    title: { rendered: renderedTitle },
    featured_media: media_ID
  } = post

  const { containerRef, isVisible } = useInView({
    rootMargin: '90px 0px 0px 0px',
    threshold: 0.2
  })

  const [featuredMediaImg, setFeaturedMediaImg] = useState(null)
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  const fetchFeaturedMediaImg = async () => {
    try {
      const res = await axios.get(`${baseEndpoint}/media/${media_ID}`)
      if (res.status !== 200) {
        setIsLoadingCategories(false)
        throw new Error('Bad request for categories.')
      }

      // setmedia here
      const image_url = res.data.source_url
      setFeaturedMediaImg(image_url)

      setIsLoadingImage(false)
    } catch (err) {
      console.log('image err: ', err)
      setIsLoadingImage(false)
    }
  }

  useEffect(() => {
    // fetch featured image initially
    if (media_ID) {
      fetchFeaturedMediaImg()
    } else {
      setIsLoadingImage(false)
    }
  }, [])

  const isInViewClass = isVisible ? 'ivtr ivtr-btm inView' : 'ivtr ivtr-btm'

  return (
    <article className={`col-v2 ${isInViewClass}`} ref={containerRef}>
      <div className="partner-card">
        <div className="partner-card__inner">
          {isLoadingImage && (
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          )}
          {!isLoadingImage && featuredMediaImg && (
            <img src={featuredMediaImg} alt={renderedTitle} />
          )}
        </div>
      </div>
    </article>
  )
}

export default memo(PartnerPostCard)
