import { useRef, useState, useEffect } from 'react'

const useInView = options => {
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  let observer

  const callbackFunction = entries => {
    const [entry] = entries

    if (entry.isIntersecting) {
      setIsVisible(true)
      observer.unobserve(entry.target)
    }
  }

  useEffect(() => {
    observer = new IntersectionObserver(callbackFunction, options)
    if (containerRef.current) observer.observe(containerRef.current)

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current)
    }
  }, [containerRef, options])

  return { containerRef, isVisible }
}

export default useInView
