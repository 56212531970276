import { useState, useEffect, useRef } from 'react'
import axios from 'axios'

const useFetchLogic = ({
  per_page = 10,
  typeEndpoint,
  loadMoreMethod = 'onScroll',
  defaultTaxObj,
  qsParam = false,
  shouldFetchTerms = false,
  termsUrlPart = false
}) => {
  const baseEndpoint = `${wrwps_site_url}wp-json/wp/v2`
  // const $secWrap = document.querySelector('.main-content')

  const postsEndpoint = `${baseEndpoint}/${typeEndpoint}`
  const POSTS_PER_PAGE = per_page

  let observer = null

  const [posts, setPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [postsCount, setPostsCount] = useState(0)
  const [totalPagesCount, setTotalPagesCount] = useState(null)
  const [totalPostsCount, setTotalPostsCount] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [isLoadingTerms, setIsLoadingTerms] = useState(false)
  const [terms, setTerms] = useState([])
  const [activeTerm, setActiveTerm] = useState(defaultTaxObj)
  const [prevActiveTerm, setPrevActiveTerm] = useState({
    slug: 'starting-prev-slug'
  })
  const [fetchedAllPosts, setFetchedAllPosts] = useState(false)
  const [initialLoadFinished, setInitialLoadFinished] = useState(false)
  const [uiLoaderEl, setUILoaderEL] = useState(null)

  const loaderRef = useRef()

  const nothingFound = (posts.length === 0 && !isLoading) || posts.length === 0
  const shouldShowLoader = isLoading || !initialLoadFinished
  const ifShowLoader = !fetchedAllPosts && initialLoadFinished && !isLoading
  const ifShowLoaderSpinner = !fetchedAllPosts && initialLoadFinished

  const qsParamJoin = typeEndpoint.includes('?') ? '&' : '?'

  const switchTerm = newTermObj => {
    setPosts([])
    setPostsCount(undefined)
    setPrevActiveTerm(activeTerm)
    setActiveTerm(newTermObj)
    setCurrentPage(1)
    setInitialLoadFinished(false)
  }

  const loadNextPage = () => {
    if (currentPage >= 1) {
      setCurrentPage(prevPage => prevPage + 1)
    }
  }

  const handleOnClick = () => {
    if (loadMoreMethod === 'onScroll') {
      return false
    } else if (loadMoreMethod === 'onClick') {
      loadNextPage()
    }
  }

  const fetchTerms = async () => {
    setIsLoadingTerms(true)

    let termsEndpoint = `${termsUrlPart}?per_page=100&page=1`
    if (termsUrlPart === 'year') {
      termsEndpoint = 'posts_years'
    }

    try {
      const res = await axios.get(`${baseEndpoint}/${termsEndpoint}`)
      if (res.status !== 200) {
        setIsLoadingTerms(false)
        throw new Error('Bad request for terms.')
      }

      const terms = res.data.map(term => ({
        id: term.id,
        slug: term.slug,
        name: term.name
      }))
      setTerms([
        {
          ...defaultTaxObj
        },
        ...terms
      ])
      setIsLoadingTerms(false)
    } catch (error) {
      console.log('terms error: ', error)
      setIsLoadingTerms(false)
    }
  }

  const fetchPosts = async currentPage => {
    if (currentPage === null) {
      return
    }

    setIsLoading(true)

    const basePostsEndpoint = `${postsEndpoint}${qsParamJoin}per_page=${POSTS_PER_PAGE}&page=${currentPage}`
    let getPostsEndpoint = basePostsEndpoint
    if (shouldFetchTerms) {
      if (
        activeTerm.slug !== defaultTaxObj.slug &&
        activeTerm.slug !== prevActiveTerm.slug
      ) {
        let switchTermEndpointPart = `${termsUrlPart}=${activeTerm.id}`
        if (termsUrlPart === 'year') {
          switchTermEndpointPart = activeTerm.slug
        }

        getPostsEndpoint = `${basePostsEndpoint}&${switchTermEndpointPart}`
      }
    } else {
      getPostsEndpoint = `${basePostsEndpoint}`
    }

    try {
      const res = await axios.get(getPostsEndpoint)

      if (res.status !== 200) {
        setIsLoading(false)
        throw new Error('Bad request.')
      }

      setTotalPagesCount(+res.headers['x-wp-totalpages'])
      setTotalPostsCount(+res.headers['x-wp-total'])

      const fetchedPosts = res.data

      if (currentPage === 1) {
        setPosts([...fetchedPosts])
        setPostsCount(fetchedPosts.length)
      } else {
        setPosts(prevPosts => [...prevPosts, ...fetchedPosts])
        setPostsCount(posts.length + fetchedPosts.length)
      }

      setIsLoading(false)
      if (!initialLoadFinished) {
        setInitialLoadFinished(true)
      }
    } catch (err) {
      console.log('Error: ', err)
      setError(err.message)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const uiLoader = loaderRef?.current?.$uiLoader

    setUILoaderEL(uiLoader)
  }, [ifShowLoader])

  useEffect(() => {
    if (postsCount === 0 && !isLoading) {
      setInitialLoadFinished(true)
    }
  }, [postsCount, isLoading])

  useEffect(() => {
    if (currentPage === totalPagesCount || totalPagesCount === 0) {
      setFetchedAllPosts(true)
    } else {
      setFetchedAllPosts(false)
    }
  }, [currentPage, totalPagesCount])

  useEffect(() => {
    const initFirstPage = (e, initial = false) => {
      setCurrentPage(1)
      setFetchedAllPosts(false)
      setPrevActiveTerm(defaultTaxObj)
      setActiveTerm(defaultTaxObj)
    }

    initFirstPage(null, true)

    // fetch terms initially
    if (shouldFetchTerms) {
      fetchTerms()
    }
  }, [])

  useEffect(() => {
    if (!uiLoaderEl) return

    if (loadMoreMethod === 'onScroll') {
      const observeScroll = () => {
        if (totalPagesCount !== null && totalPagesCount > 1) {
          if (currentPage < totalPagesCount && !isLoading) {
            observer = new IntersectionObserver(
              (entries, observer) => {
                entries.forEach(entry => {
                  if (entry.isIntersecting) {
                    loadNextPage()
                  }
                })
              },
              { rootMargin: '0px 0px -100px 0px' }
            )
            ;[uiLoaderEl].forEach(entry => observer.observe(entry))
          }
        }
      }
      observeScroll()
    }

    return () => {
      if (loadMoreMethod === 'onScroll') {
        return (observer = null)
      }
    }
  }, [totalPagesCount, isLoading, uiLoaderEl, loadMoreMethod])

  useEffect(() => {
    // check if query string is present and filter based on it if found
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const qsTerm = urlParams.get(qsParam) // == article

    if (qsTerm && terms) {
      let foundTermObj = false
      if ((foundTermObj = terms.find(term => term.slug === qsTerm))) {
        setPrevActiveTerm(activeTerm)
        setActiveTerm(foundTermObj)
      }
    }
  }, [terms])

  useEffect(() => {
    fetchPosts(currentPage)
  }, [activeTerm, currentPage])

  return {
    isLoadingTerms,
    terms,
    switchTerm,
    activeTerm,
    error,
    shouldShowLoader,
    posts,
    isLoading,
    postsCount,
    nothingFound,
    initialLoadFinished,
    currentPage,
    setCurrentPage,
    ifShowLoader,
    loaderRef,
    ifShowLoaderSpinner,
    handleOnClick,
    totalPagesCount
  }
}

export default useFetchLogic
