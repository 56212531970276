import React, { useRef, memo } from 'react'
import PostCard from '../PostCard/PostCard'

const ArchivePostsWrap = ({ posts, fetched_tags }) => {
  const hasImgRef = useRef(false)
  const rowIndexRef = useRef(0)

  return (
    <div className="posts-wrap">
      {posts.map((post, index) => {
        if (rowIndexRef.current % 2 === 0) {
          hasImgRef.current = index % 2 !== 0
        } else {
          hasImgRef.current = index % 2 === 0
        }
        if (index % 2 !== 0) {
          rowIndexRef.current = rowIndexRef.current + 1
        }

        return (
          <PostCard
            key={post.id}
            index={index}
            fetched_tags={fetched_tags}
            post={post}
            hasImg={hasImgRef.current}
          />
        )
      })}
    </div>
  )
}

export default memo(ArchivePostsWrap)
