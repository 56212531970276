import React, { useState, useEffect, useRef } from 'react'

const FilterIcon = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5509 6.54951H9.43114C9.23353 5.66584 8.40719 5.00743 7.43713 5.00743C6.46707 5.00743 5.64072 5.66584 5.44311 6.54951H0.449102C0.197605 6.54951 0 6.7401 0 6.98267C0 7.22525 0.197605 7.41584 0.449102 7.41584H5.44311C5.64072 8.29951 6.46707 8.95792 7.43713 8.95792C8.40719 8.95792 9.23353 8.29951 9.43114 7.41584H14.5509C14.8024 7.41584 15 7.22525 15 6.98267C15 6.75743 14.7844 6.54951 14.5509 6.54951ZM7.43713 8.09158C6.80838 8.09158 6.28743 7.58911 6.28743 6.98267C6.28743 6.37624 6.80838 5.87376 7.43713 5.87376C8.06587 5.87376 8.58683 6.37624 8.58683 6.98267C8.58683 7.58911 8.06587 8.09158 7.43713 8.09158Z"
      fill="#ffffff"
    />
    <path
      d="M2.06587 3.9505C3.03593 3.9505 3.86228 3.29208 4.05988 2.40842H14.5329C14.7844 2.40842 14.982 2.21782 14.982 1.97525C14.982 1.73267 14.7844 1.54208 14.5329 1.54208H4.05988C3.86228 0.658416 3.03593 0 2.06587 0C0.934132 0 0.017964 0.90099 0.017964 1.97525C0.017964 3.0495 0.934132 3.9505 2.06587 3.9505ZM2.06587 0.866337C2.69461 0.866337 3.21557 1.36881 3.21557 1.97525C3.21557 2.58168 2.69461 3.08416 2.06587 3.08416C1.43713 3.08416 0.916168 2.58168 0.916168 1.97525C0.916168 1.36881 1.43713 0.866337 2.06587 0.866337Z"
      fill="#ffffff"
    />
    <path
      d="M12.9341 10.0495C11.9641 10.0495 11.1557 10.7079 10.9401 11.5743H0.449102C0.197605 11.5743 0 11.7649 0 12.0074C0 12.25 0.197605 12.4406 0.449102 12.4406H10.9401C11.1377 13.3243 11.9641 14 12.9341 14C14.0659 14 14.982 13.1163 14.982 12.0248C14.982 10.9332 14.0479 10.0495 12.9341 10.0495ZM12.9341 13.1163C12.3054 13.1163 11.7844 12.6139 11.7844 12.0074C11.7844 11.401 12.3054 10.8985 12.9341 10.8985C13.5629 10.8985 14.0838 11.401 14.0838 12.0074C14.0838 12.6139 13.5629 13.1163 12.9341 13.1163Z"
      fill="#ffffff"
    />
  </svg>
)

const DropdownSelect = ({ options, activeOption, onSelectNew, label }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ddRef = useRef(null)

  const handleSelectNew = optionObj => {
    onSelectNew(optionObj)
    setIsOpen(false)
  }

  const handleClickOutside = e => {
    if (ddRef.current && !ddRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
  }, [])

  return (
    <div className="dropdown-wrap dropdown-react-wrap">
      {label && <div className="dropdown-label">{label}</div>}

      <div
        ref={ddRef}
        className={`dropdown ${isOpen ? 'show' : ''}`}
        data-active-filter-option={activeOption.slug}
      >
        <button
          type="button"
          className={`dropdown-toggle ${isOpen ? 'active' : ''}`}
          onClick={() => setIsOpen(prevOpen => !prevOpen)}
        >
          <FilterIcon />
          <span>{activeOption.name}</span>
        </button>
        <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
          <div className="dropdown-menu__items">
            {options.map(option => (
              <div key={option.id} className="dropdown-item-wrap">
                <button
                  type="button"
                  className={`dropdown-item ${
                    activeOption.slug === option.slug ? 'active' : ''
                  }`}
                  data-value={option.slug}
                  onClick={() => handleSelectNew(option)}
                >
                  {option?.plural || option.name}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DropdownSelect
