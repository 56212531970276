import React, { memo } from 'react'
import Loader, { GhostLoader } from '../Loader/Loader'
import useFetchLogic from './useFetchLogic'
import DropdownSelect from '../DropdownSelect/DropdownSelect'
import ArchivePostsWrap from '../ArchivePostsWrap/ArchivePostsWrap'

const Archive = ({ typeEndpoint, qsParam = 'tag' }) => {
  const {
    isLoadingTerms,
    terms,
    switchTerm,
    activeTerm,
    error,
    shouldShowLoader,
    posts,
    isLoading,
    postsCount,
    nothingFound,
    initialLoadFinished,
    currentPage,
    setCurrentPage,
    ifShowLoader,
    loaderRef,
    ifShowLoaderSpinner,
    totalPagesCount,
    handleOnClick
  } = useFetchLogic({
    typeEndpoint,
    per_page: 8,
    loadMoreMethod: 'onClick',
    defaultTaxObj: {
      name: qsParam === 'tag' ? 'All Tags' : 'All Years',
      slug: qsParam === 'tag' ? 'all-tags' : 'all-years',
      id: qsParam === 'tag' ? 'all-tags' : 'all-years'
    },
    qsParam,
    shouldFetchTerms: true,
    termsUrlPart: qsParam === 'tag' ? 'tags' : 'year'
  })

  return (
    <>
      {!isLoadingTerms && terms.length ? (
        <div className="postsWrapFilter">
          <DropdownSelect
            onSelectNew={switchTerm}
            options={terms}
            activeOption={activeTerm}
            label={`Filter by ${qsParam === 'tag' ? 'tags' : 'years'}:`}
          />
        </div>
      ) : null}

      {error && <div className="react-error">{error}</div>}
      {shouldShowLoader ? (
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      ) : null}

      {posts.length ? (
        <ArchivePostsWrap
          fetched_tags={terms}
          posts={posts}
          postsCount={postsCount}
        />
      ) : null}

      {nothingFound && initialLoadFinished && (
        <div className="no-posts-wrap">
          <h3>No Posts Found</h3>
          <p>Try updating the filter</p>
        </div>
      )}

      {totalPagesCount && totalPagesCount > 1 ? (
        <>
          {ifShowLoader ? <GhostLoader ref={loaderRef} /> : null}
          {ifShowLoaderSpinner ? (
            <Loader handleOnClick={handleOnClick} isSpinning={isLoading} />
          ) : null}
        </>
      ) : null}
    </>
  )
}

export default memo(Archive)
