import React, { useRef, useImperativeHandle, forwardRef } from 'react'

const Loader = props => {
  const { isSpinning = false } = props

  return (
    <div className="uiLoader btn btn--brand" onClick={props.handleOnClick}>
      More
    </div>
  )
}
export default Loader

const GhostLoader = forwardRef((props, ref) => {
  const ghostLoaderRef = useRef()

  useImperativeHandle(ref, () => {
    return {
      $uiLoader: ghostLoaderRef.current
    }
  })

  return <div ref={ghostLoaderRef}></div>
})

export { GhostLoader }
